import { Injectable } from '@angular/core';
import { initializeApp} from 'firebase/app'
import { getMessaging ,onMessage ,getToken } from "firebase/messaging";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  messagingFirebase
  constructor() { 
    const app = initializeApp(environment.firebaseConfig)
    this.messagingFirebase = getMessaging(app)
  }
  requestPermission=()=>{
    return new Promise(async(resolve,reject)=>{
      const permiss = await Notification.requestPermission();
      if(permiss == "granted"){
        // console.log("permission granted")
        const firebaseToken = await getToken(this.messagingFirebase) 
        resolve(firebaseToken)
      }
      else{
        reject(new Error("Permission Denied"))
      }
    })  
  }
  private messagingObservable = new Observable (
    (observer)=>{
      onMessage(this.messagingFirebase ,(payload)=>{
        observer.next(payload)
      })
    }
  )
  receiveMessage(){
    return this.messagingObservable
  }
  
}