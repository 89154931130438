import { NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from './service/push-notification.service'
import { UiService } from 'src/app/service/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private ui: UiService,
    private notification: PushNotificationService
  ) {
    Notification.requestPermission().then((permission) => {
      if (permission == "granted") {
        notification.requestPermission().then(data => {
        })
      }
    })
  }

  async ngOnInit() {
    this.notification.receiveMessage().subscribe(payload => {
      let noti: any = payload;
      this.ui.showAlert(noti.notification.title,noti.notification.body,['OK'],"")
    })
  }
}
