import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SesionGuard implements CanActivate {
  constructor(
    private storage: Storage,
    public navCtrl: NavController,
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.storage = new Storage();
    this.storage.create();

    this.storage.get('storage_xxx').then((res) => {
      if (res) {
        return true;
      } else {
        this.navCtrl.navigateRoot(['/principal']);
        return false;
      }
    });
    return;

  }



}
