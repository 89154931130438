import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SesionGuard } from 'src/app/guards/sesion.guard';

const routes: Routes = [
  
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full'
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'principal',
    loadChildren: () => import('./pages/principal/principal.module').then( m => m.PrincipalPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'cuenta',
    loadChildren: () => import('./pages/dashboard-home/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'softic',
    loadChildren: () => import('./pages/softic/softic.module').then( m => m.SofTicPageModule)
  },
  {
    path: 'autorizacion',
    loadChildren: () => import('./pages/autorizacion/autorizacion.module').then( m => m.AutorizacionPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard-home/dashboard-home.module').then( m => m.DashboardHomePageModule),
    canActivate: [SesionGuard]
  },
  {
    path: 'terminos-condiciones',
    loadChildren: () => import('./pages/terminos-condiciones/terminos-condiciones.module').then( m => m.TerminosCondicionesPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
