import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';


@Injectable()
export class AccessProviders{
  server: string=environment.servicios.server;
  server2: string=environment.servicios.server; 


  myFoto:string='';
  constructor(    
    public http:HttpClient,

    ) { }  

    postData(body,file){

      let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };

      return this.http.post(this.server + file, JSON.stringify(body), httpOptions)
      .timeout(59000) //
      .map(res => res);
    }

    postData_URL(body,file){

   let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };

      return this.http.post(file, JSON.stringify(body), httpOptions)
      .timeout(59000) //
      .map(res => res);
    }

    postDataTest(body,file){
     let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' };

      return this.http.post(this.server2 + file, JSON.stringify(body), httpOptions)
      .timeout(59000) //
      .map(res => res);
    }


   
}