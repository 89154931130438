// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const baseUrl = "http://localhost:3000";
const baseUrl = "https://backend-besafe.azurewebsites.net/api";
export const environment = {
  production: true,

  servicios: {
    server: baseUrl,
    // organizaciones
    getOrgs: baseUrl + "/getOrgs",
    getOrg: baseUrl + "/getOrg/",
    saveOrg: baseUrl + "/saveOrg",
    // usuarios
    usuarios: baseUrl + '/getUsers',
    countUsuarios: baseUrl + '/CountUsersOrg',
    usuarios_Org: baseUrl + '/getUser_Organizacion',
    usuario: baseUrl + '/getUser/{userId}',
    updateUsuario: baseUrl + '/updateUsuario/{userId}',
    updateUsuarioToken: baseUrl + '/updateUsuario_Token/{userId}',
    usuarioLogin: baseUrl + '/loginUser',
    updateSessionUsuario: baseUrl + '/updatesession_Usuario/{userId}',
    saveUsuario: baseUrl + '/saveUsers/',
    saveImg: baseUrl + '/saveImg/',
    // encuestas
    formulario: baseUrl + '/getFormularios/',
    formulariodb: baseUrl + '/getFormulariosdb/',
    getRespuestasUser: baseUrl + '/getRespuestasUser/',
    getReport_Ind: baseUrl + '/getRespuestasReport_Ind/',
    countRespuestas_Grup: baseUrl + '/getRespuestas_GrupCount',
    getRespuestas_GrupReport: baseUrl + '/getRespuestasReport_Grup',
    getRespuestasAll_Grup: baseUrl + "/getRespuestasAll_Grup",
    RecognitionDataOrg: baseUrl +"/selectOrgDataRecognition/",
    recognitionData: baseUrl + '/recognitionImg/',
    recognitionDatabase46: baseUrl + '/recognitionImgPost/',
    recognitionSave: baseUrl + '/saveDataRecognition/',
    recognitionSelectUser: baseUrl + '/getUserDataRecognition/{userId}',
    recognitionSelectUserReg: baseUrl + '/getUserDataRecognitionReg/{userId}',
    recognitionSelectUserLimit: baseUrl + '/getUserDataRecognitionParam/{userId}/{limit}',
    preguntasFormulario: baseUrl + '/getPreguntas_Id/{idFormulario}',

    // Lex
    // sessionLex:'https://bot-besafe.azurewebsites.net/AWSApi/GetSession',
    sessionLex: 'https://bot-besafe.azurewebsites.net/AWSApi/GetSession',
    botLex: baseUrl + '/GetRecongizeTextV2Response',
    compareFace: baseUrl + '/CompareFaces',
    // botLex: 'https://bot-besafe.azurewebsites.net/AWSApi/GettextResponse',


    // Preguntas de autenticacion
    getPreguntas: baseUrl + '/getAllPreguntas',
    savePreguntas: baseUrl + '/savePreguntas',

    //Ergonomia,
    getActividad: baseUrl + '/getActividadId/{actividadId}',
    getPhotosActividad: baseUrl + "/selectPhotosActividad/{actividadId}",
    saveNivel: baseUrl + '/saveNivel',
    allNivel: baseUrl + '/getAllNivel',
    saveParametro: baseUrl + '/saveParametro',
    allParametro: baseUrl + '/getAllParametro',
    saveActividad: baseUrl + '/saveActividad',
    getAllActividad: baseUrl + '/getActividadesAllE',

    getAllActividadUserEstado: baseUrl + '/getActividadIdEstado/{userId}/{estado}',
    getAllActividadUser: baseUrl + '/getUserDataActividad/{userId}',
    updateActividad: baseUrl + '/updateActividad/{actividadId}',

    // Ficha de datos
    getFichaReport: baseUrl + '/selectAllFicha',

    // reportes
    getInformes: baseUrl + '/getInformeOrganizacion',
    getInforme: baseUrl + '/getInforme/{id}',
    saveInforme: baseUrl + '/saveInformeGeneral',

    //Organizacion
    updateOrganization: baseUrl +'/updateOrg'
  },
  firebaseConfig: {
    apiKey: "AIzaSyBOHOyf5n6pIS17oqiTadLEVZo8YWRCBBc",
    authDomain: "besafe360-6e186.firebaseapp.com",
    projectId: "besafe360-6e186",
    storageBucket: "besafe360-6e186.appspot.com",
    messagingSenderId: "810236772991",
    appId: "1:810236772991:web:c7dd06923f60d89308754a",
    measurementId: "G-5DKCW324T3"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
