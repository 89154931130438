import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//************
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule } from '@angular/common/http';
import { AccessProviders } from './providers/access-provides';
//import {WebcamModule} from 'ngx-webcam';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DataTablesModule } from "angular-datatables";

// import { Camera } from '@awesome-cordova-plugins/camera/ngx';
// import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
// import { NgVerticalTimelineModule  } from 'ng-vertical-timeline';
// import { NgxVerticalTimelineModule } from 'ngx-vertical-timeline';
// import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx';




@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    DataTablesModule,
    FormsModule, ReactiveFormsModule
    // NgVerticalTimelineModule,
    // NgxVerticalTimelineModule,
    //WebcamModule,
  ],
  providers: [
    AccessProviders,
    DatePipe,
    // Camera,
    // AndroidPermissions,
    // CameraPreview,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,

    }],
  bootstrap: [AppComponent],
})
export class AppModule { }
