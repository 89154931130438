
import { LoadingComponent } from 'src/app/utils/_components/loading/loading.component';
import { Injectable, ComponentRef, ChangeDetectorRef } from '@angular/core';
import { ToastController, LoadingController, AlertController, PopoverController, ModalController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UiService {

  private toast: HTMLIonToastElement;
  private loading: HTMLIonModalElement;
  private loadingDialog: HTMLIonLoadingElement;

  private isUpdating = false;
  private refreshSbj = new BehaviorSubject<boolean>(this.isUpdating);
  public refresh$ = this.refreshSbj.asObservable();

  private currentPageSbj = new BehaviorSubject<number>(undefined);
  public currentPage$ = this.currentPageSbj.asObservable();

  constructor(private toastController: ToastController,
    private alertController: AlertController,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
  ) {
  }

  setCurrentPage(pageId: number) {
    this.currentPageSbj.next(pageId);
  }

  quickRefresh() {
    this.isUpdating = true;
    console.log('ui > quickRefresh');
    this.refreshSbj.next(this.isUpdating);
    this.isUpdating = false;
    this.refreshSbj.next(this.isUpdating);
  }

  async showAlert(title: string, msg: string, buttonsConfig: any, cssConfig?: string) {

    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: buttonsConfig,
      cssClass: cssConfig
    });

    await alert.present();
  }

  async showToast(text,
    pos?: any | 'bottom' | 'top' | 'middle',
    className?: any,
    durationMS?: number,
    closeBtnText?: string,
    closeCBFn?: any) {
    if (pos === undefined) { pos = 'bottom'; }
    if (className === undefined || className === '') { className = 'toast-dark'; }
    if (this.toast) {
      this.toast.dismiss('timeout');
    }
    this.toast = await this.toastController.create({
      message: text,
      position: pos,
      //showCloseButton: (closeBtnText !== undefined),
      //closeButtonText: closeBtnText,
      duration: durationMS ? durationMS : 5000,
      cssClass: className
    });
    // Handle "undo" action
    this.toast.onDidDismiss().then((res) => {
      if (res.role === 'cancel') {
        closeCBFn();
      }
    });
    this.toast.present();
  }

  async showLoading(text?: string) {
    try {
      // this.dismissLoading();
      this.loading = await this.modalCtrl.create({
        component: LoadingComponent,
        cssClass: 'loading-modal',
        animated: true,
        showBackdrop: true,
        backdropDismiss: false,
      });
      await this.loading.present();
    } catch (e) {
      console.error('showLoading', e);
    }
  }

  dismissLoading(timeOut: number = 1000) {
    try {
      if (this.loading) { this.loading.dismiss('cancel'); }
    } catch (e) {
      console.error('dismissLoading', e);
    }
    setTimeout(() => {
      // Confirm dismissed was done
      this.modalCtrl.getTop().then((topOverlay) => {
        if (topOverlay !== undefined) {
          this.modalCtrl.dismiss();
        }
      });
    }, timeOut);
    // cdr.detectChanges();
    // cdr.markForCheck();
  }

  async showLoadingDialog(text: string) {
    this.loadingDialog = await this.loadingCtrl.create({
      message: text
    });
    await this.loadingDialog.present();
  }

  async dismissLoadingDialog() {
    try {
      if (this.loadingDialog) { this.loadingDialog.dismiss(); }
    } catch (e) {
      console.error('dismissLoading', e);
    }
  }

  async showPopUp(comp: any, args?: {}, classCss?: string) {
    const mockEvent: any = {
      target: {
        getBoundingClientRect: () => {
          return {
            //top: (ev.target as any).getBoundingClientRect().top,
            // left: ev.pageX
          };
        }
      }
    };
    const popover = await this.popoverCtrl.create({
      component: comp,
      componentProps: args,
      event: mockEvent,
      animated: true,
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: classCss
    });

    popover.onDidDismiss()
      .then((data) => {
        if (args !== undefined) {
          if (args.hasOwnProperty('el') && data.role === 'backdrop') {
            args['el'].setAttribute('checked', 'false');
          }
        }
      });
    await popover.present();
    return popover;
  }

  dismissPopUp() {
    this.popoverCtrl.dismiss();
  }

  dismissAlert() {
    this.alertController.dismiss();
  }

  getFechaHora(date: Date) {

    let dd = date.getDate();
    let mm = date.getMonth() + 1; //As January is 0.
    let yyyy = date.getFullYear();
    let hour = date.getHours();
    let minu = date.getMinutes();
    let ss = date.getSeconds();
    let dia = ""
    let mes = ""
    let min = ""
    let seg = ""
    let hora = ""
    if (dd < 10) { dia = '0' + dd }else{dia=''+dd};
    if (mm < 10) { mes = '0' + mm }else{mes=''+mm};
    if (minu < 10) { min = '0' + minu }else{min = '' + minu};
    if (ss < 10) { seg = '0' + ss }else{ seg = '' + ss };
    if (hour < 10) { hora = '0' + hour }else{hora = '' + hour};

    return dia + '-' + mes + '-' + yyyy + ' ' + hora + ':' + min + ':' + seg;
  }

  getFecha(date: Date) {

    let dd = date.getDate();
    let mm = date.getMonth() + 1; //As January is 0.
    let yyyy = date.getFullYear();
    let hour = date.getHours();
    let minu = date.getMinutes();
    let ss = date.getSeconds();
    let dia = ""
    let mes = ""
    let min = ""
    let seg = ""
    let hora = ""
    if (dd < 10) { dia = '0' + dd }else{dia=''+dd};
    if (mm < 10) { mes = '0' + mm }else{mes=''+mm};
    if (minu < 10) { min = '0' + minu }else{min = '' + minu};
    if (ss < 10) { seg = '0' + ss }else{ seg = '' + ss };
    if (hour < 10) { hora = '0' + hour }else{hora = '' + hour};

    return dia + '/' + mes + '/' + yyyy;
  }

}
